<template>
  <div>
    <v-form>
      <p class="mb-3">
        The singlet at 9.7 ppm is characteristic of which functional group? (Spelling counts!)
      </p>
      <p>
        functional group:
        <v-text-field v-model="inputs.writtenAnswer" style="display: inline-block; width: 15em" />
      </p>

      <p class="mb-3">
        The two doublets at 7.75 and 6.65 ppm are characteristic of which functional group?
      </p>
      <v-radio-group v-model="inputs.radioAnswer" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option"
          class="my-3"
          :value="option"
        >
          <template #label>
            <stemble-latex :content="option" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2_M6Q2b',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        writtenAnswer: null,
        radioAnswer: null,
      },
      options: ['alkene', 'alkyne'],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
